import axios from "axios";
import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import Avatar from "../../../../../../../../assets/images/avatarr.png";
import { switchEndpoint } from "../../../../../../../../redux/actions/endpoints";
import "./index.css";
import capitalize from "../../../../../../../../capitalize";

const PaymentReqModal = ({
  showAwaiting,
  handleCloseAwaiting,
  dropOffData,
  handleShowPaymentR,
  steps,
  setSteps,
  getAllReq
}) => {
  const ref = JSON.parse(localStorage.getItem("reference"));
  // console.log(ref);
  const dropData = JSON.parse(localStorage.getItem("dropOff"));
  const code = localStorage.getItem("code");
  const name = localStorage.getItem("name");
  const methods = localStorage.getItem("paymentMethod");
  // console.log(dropOffData);
  const getDropOffss = (code) => {
    const token = localStorage.getItem("token");
    axios
      .get(`${switchEndpoint}/collection-center/single-dropoff/${code}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        localStorage.setItem("reference", JSON.stringify(response.data.data));
        if (response.data.data.status === "accepted") {
          handleCloseAwaiting();
          handleShowPaymentR();
          setSteps(0);
          localStorage.removeItem("dropOff");
        }
        if (response.data.data.status === "rejected") {
          handleShowPaymentR();
          setSteps(0);
        }
      })
      .catch((error) => {
        handleCloseAwaiting();
        setSteps(0);
        localStorage.removeItem("dropOff");
        getAllReq();
        //   // console.log(error)
        //   toast.error(error.response.data.message, {
        //     position: "top-right",
        //     autoClose: 5000,
        //     hideProgressBar: false,
        //     pauseOnHover: true,
        //     progress: undefined,
        //     theme: "light",
        //   });
      });
  };

  // console.log(steps)

  useEffect(() => {
    if (steps === 1) {
      const interval = setInterval(() => {
        // console.log("Logs every 5 secs")
        getDropOffss(dropOffData.code);
      }, 1000 * 5);
      return () => clearInterval(interval);
    }
  }, [steps === 1]);
  return (
    <div>
      <Modal
        show={showAwaiting}
        onHide={() => {
          handleCloseAwaiting();
          setSteps(0)
        }}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="text-left ml-3">
            <div className="mb-2">
              <h5 className="sec-headers mb-3">Waiting for confirmation</h5>
              <div className="d-flex justify-content-between align-items-center">
                <p className="ftsz-regular" style={{ fontSize: "14px" }}>
                  {capitalize(dropOffData?.owner?.roles[0])}:
                  {dropOffData?.owner?.name}
                </p>
                <div className="d-flex align-items-center ">
                  <div>
                    <p
                      className="mb-0"
                      style={{ fontSize: "14px", color: "#898989" }}
                    >
                      Drop off Code
                    </p>
                    <p
                      style={{
                        fontSize: "14px",
                        color: "#404040",
                        fontWeight: "700",
                      }}
                    >
                      {dropOffData?.code}
                    </p>
                  </div>
                  <div className="imgAvatarHolder ml-3">
                    <img src={Avatar} alt="image" />
                  </div>
                </div>
              </div>

              {dropData?.clean_sorted?.KG > 0 ||
                (dropOffData?.dropoff_details?.clean_sorted?.KG > 0 && (
                  <div>
                    <div className="reqBg d-flex pl-4 w-50">
                      <p className="mb-0 mr-3">Clean Sorted</p>
                      <div className="d-flex">
                        <p className="fontBold">{`${dropData
                          ? dropData?.clean_sorted?.KG
                          : dropOffData?.dropoff_details?.clean_sorted?.KG
                          }kg`}</p>
                        <span className="mx-3" style={{ color: "#c4da00" }}>
                          &#8644;
                        </span>
                        <p className="fontBold">{`₦${dropData
                          ? dropData?.clean_sorted?.amount?.toFixed(2)
                          : dropOffData?.dropoff_details?.clean_sorted.amount?.toFixed(
                            2
                          )
                          }`}</p>
                      </div>
                    </div>
                  </div>
                ))}

              {dropData?.clean_unsorted?.KG > 0 ||
                (dropOffData?.dropoff_details?.clean_unsorted?.KG > 0 && (
                  <div>
                    <div className="reqBg d-flex pl-4 w-50">
                      <p className="mb-0 mr-3">Clean Unsorted</p>
                      <div className="d-flex">
                        <p className="fontBold">{`${dropData
                          ? dropData?.clean_unsorted?.KG
                          : dropOffData?.dropoff_details?.clean_unsorted?.KG
                          }kg`}</p>
                        <span className="mx-3" style={{ color: "#c4da00" }}>
                          &#8644;
                        </span>
                        <p className="fontBold">{`₦${dropData
                          ? dropData?.clean_unsorted?.amount?.toFixed(2)
                          : dropOffData?.dropoff_details?.clean_unsorted?.toFixed(
                            2
                          ).amount
                          }`}</p>
                      </div>
                    </div>
                  </div>
                ))}

              {(dropData?.mixed_plastics?.KG > 0 ||
                dropOffData?.dropoff_details?.mixed_plastics?.KG > 0) && (
                  <div>
                    <div className="reqBg d-flex pl-4 w-50">
                      <p className="mb-0 mr-3">Mixed Plastics</p>
                      <div className="d-flex">
                        <p className="fontBold">
                          {dropData
                            ? `${dropData?.mixed_plastics?.KG}kg`
                            : `${dropOffData?.dropoff_details?.mixed_plastics?.KG}kg`}
                        </p>
                        <span className="mx-3" style={{ color: "#c4da00" }}>
                          &#8644;
                        </span>
                        <p className="fontBold">
                          {" "}
                          {dropData
                            ? `₦${dropData?.mixed_plastics?.amount?.toFixed(2)}`
                            : `₦${dropOffData?.dropoff_details?.mixed_plastics?.amount?.toFixed(
                              2
                            )}`}
                        </p>
                      </div>
                    </div>
                  </div>
                )}

              {(dropData?.pure_water_sachet_dry?.KG > 0 ||
                dropOffData?.dropoff_details?.pure_water_sachet_dry?.KG >
                0) && (
                  <div>
                    <div className="reqBg d-flex pl-4 w-50">
                      <p className="mb-0 mr-3">Pure Water Nylon</p>
                      <div className="d-flex">
                        <p className="fontBold">
                          {dropData
                            ? `${dropData?.pure_water_sachet_dry?.KG}kg`
                            : `${dropOffData?.dropoff_details?.pure_water_sachet_dry?.KG}kg`}
                        </p>
                        <span className="mx-3" style={{ color: "#c4da00" }}>
                          &#8644;
                        </span>
                        <p className="fontBold">
                          {" "}
                          {dropData
                            ? `₦${dropData?.pure_water_sachet_dry?.amount?.toFixed(
                              2
                            )}`
                            : `₦${dropOffData?.dropoff_details?.pure_water_sachet_dry?.amount?.toFixed(
                              2
                            )}`}
                        </p>
                      </div>
                    </div>
                  </div>
                )}

              {(dropData?.single_colour_caps?.KG > 0 ||
                dropOffData?.dropoff_details?.single_colour_caps?.KG > 0) && (
                  <div>
                    <div className="reqBg d-flex pl-4 w-50">
                      <p className="mb-0 mr-3">Single color caps</p>
                      <div className="d-flex">
                        <p className="fontBold">
                          {dropData
                            ? `${dropData?.single_colour_caps?.KG}kg`
                            : `${dropOffData?.dropoff_details?.single_colour_caps?.KG}kg`}
                        </p>
                        <span className="mx-3" style={{ color: "#c4da00" }}>
                          &#8644;
                        </span>
                        <p className="fontBold">
                          {" "}
                          {dropData
                            ? `₦${dropData?.single_colour_caps?.amount?.toFixed(
                              2
                            )}`
                            : `₦${dropOffData?.dropoff_details?.single_colour_caps?.amount?.toFixed(
                              2
                            )}`}
                        </p>
                      </div>
                    </div>
                  </div>
                )}

              {(dropData?.hard_packing_bags_dry?.KG > 0 ||
                dropOffData?.dropoff_details?.hard_packing_bags_dry?.KG >
                0) && (
                  <div>
                    <div className="reqBg d-flex pl-4 w-50">
                      <p className="mb-0 mr-3">Hard packing bags (dry)</p>
                      <div className="d-flex">
                        <p className="fontBold">
                          {dropData
                            ? `${dropData?.hard_packing_bags_dry?.KG}kg`
                            : `${dropOffData?.dropoff_details?.hard_packing_bags_dry?.KG}kg`}
                        </p>
                        <span className="mx-3" style={{ color: "#c4da00" }}>
                          &#8644;
                        </span>
                        <p className="fontBold">
                          {" "}
                          {dropData
                            ? `₦${dropData?.hard_packing_bags_dry?.amount?.toFixed(
                              2
                            )}`
                            : `₦${dropOffData?.dropoff_details?.hard_packing_bags_dry?.amount?.toFixed(
                              2
                            )}`}
                        </p>
                      </div>
                    </div>
                  </div>
                )}

              {(dropData?.hdpe_plastics_below_five_meters?.KG > 0 ||
                dropOffData?.dropoff_details?.hdpe_plastics_below_five_meters
                  ?.KG > 0) && (
                  <div>
                    <div className="reqBg d-flex pl-4 w-50">
                      <p className="mb-0 mr-3">HDPE plastics below 5 Litres</p>
                      <div className="d-flex">
                        <p className="fontBold">
                          {dropData
                            ? `${dropData?.hdpe_plastics_below_five_meters?.KG}kg`
                            : `${dropOffData?.dropoff_details?.hdpe_plastics_below_five_meters?.KG}kg`}
                        </p>
                        <span className="mx-3" style={{ color: "#c4da00" }}>
                          &#8644;
                        </span>
                        <p className="fontBold">
                          {" "}
                          {dropData
                            ? `₦${dropData?.hdpe_plastics_below_five_meters?.amount?.toFixed(
                              2
                            )}`
                            : `₦${dropOffData?.dropoff_details?.hdpe_plastics_below_five_meters?.amount?.toFixed(
                              2
                            )}`}
                        </p>
                      </div>
                    </div>
                  </div>
                )}

              {(dropData?.pp_plastics?.KG > 0 ||
                dropOffData?.dropoff_details?.pp_plastics?.KG > 0) && (
                  <div>
                    <div className="reqBg d-flex pl-4 w-50">
                      <p className="mb-0 mr-3">PP Plastics</p>
                      <div className="d-flex">
                        <p className="fontBold">
                          {dropData
                            ? `${dropData?.pp_plastics?.KG}kg`
                            : `${dropOffData?.dropoff_details?.pp_plastics?.KG}kg`}
                        </p>
                        <span className="mx-3" style={{ color: "#c4da00" }}>
                          &#8644;
                        </span>
                        <p className="fontBold">
                          {" "}
                          {dropData
                            ? `₦${dropData?.pp_plastics?.amount?.toFixed(2)}`
                            : `₦${dropOffData?.dropoff_details?.pp_plastics?.amount?.toFixed(
                              2
                            )}`}
                        </p>
                      </div>
                    </div>
                  </div>
                )}

              {(dropData?.packing_bags?.KG > 0 ||
                dropOffData?.dropoff_details?.packing_bags?.KG > 0) && (
                  <div>
                    <div className="reqBg d-flex pl-4 w-50">
                      <p className="mb-0 mr-3">Pure Water Packaging Bags</p>
                      <div className="d-flex">
                        <p className="fontBold">
                          {dropData
                            ? `${dropData?.packing_bags?.KG}kg`
                            : `${dropOffData?.dropoff_details?.packing_bags?.KG}kg`}
                        </p>
                        <span className="mx-3" style={{ color: "#c4da00" }}>
                          &#8644;
                        </span>
                        <p className="fontBold">
                          {" "}
                          {dropData
                            ? `₦${dropData?.packing_bags?.amount?.toFixed(2)}`
                            : `₦${dropOffData?.dropoff_details?.packing_bags?.amount?.toFixed(
                              2
                            )}`}
                        </p>
                      </div>
                    </div>
                  </div>
                )}

              {(dropData?.cans?.KG > 0 ||
                dropOffData?.dropoff_details?.cans?.KG > 0) && (
                  <div>
                    <div className="reqBg d-flex pl-4 w-50">
                      <p className="mb-0 mr-3">Cans</p>
                      <div className="d-flex">
                        <p className="fontBold">
                          {dropData
                            ? `${dropData?.cans?.KG}kg`
                            : `${dropOffData?.dropoff_details?.cans?.KG}kg`}
                        </p>
                        <span className="mx-3" style={{ color: "#c4da00" }}>
                          &#8644;
                        </span>
                        <p className="fontBold">
                          {" "}
                          {dropData
                            ? `₦${dropData?.cans?.amount?.toFixed(2)}`
                            : `₦${dropOffData?.dropoff_details?.cans?.amount?.toFixed(
                              2
                            )}`}
                        </p>
                      </div>
                    </div>
                  </div>
                )}

              <div className="mb-3 mr-3 w-50">
                <label className="mb-1 form-label">Deductions</label>
                <input
                  type="text"
                  className="form-control form-control-lg inputStyle bg-dark text-white"
                  // placeholder="-N0.00"
                  value={`₦${dropData
                    ? dropData?.total_deduction?.toFixed(2)
                    : dropOffData?.dropoff_details?.total_deduction?.toFixed(
                      2
                    )
                    }`}
                  disabled
                />
              </div>

              <div>
                <div className="totalBg d-flex pl-4 w-50">
                  <p className="mb-0 mr-3">Sum Total</p>
                  <div className="d-flex">
                    <p className="fontBold mb-0">{`${dropData
                      ? dropData?.total_kg
                      : dropOffData?.dropoff_details?.total_kg
                      }kg`}</p>
                    <span className="mx-3" style={{ color: "#c4da00" }}>
                      &#8644;
                    </span>
                    <p className="fontBold mb-0">{`₦${dropData
                      ? dropData?.total_price?.toFixed(2)
                      : dropOffData?.dropoff_details?.total_price?.toFixed(2)
                      }`}</p>
                  </div>
                </div>
              </div>

              <div className="my-4">
                <p>
                  Payment Method:{" "}
                  {methods ? methods : dropOffData?.payment_method}
                </p>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default PaymentReqModal;
